import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {DarkTheme} from './Themes';
import { BrowserView, MobileView } from 'react-device-detect';
import SocialIcons from '../subComponents/SocialIcons';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitle'

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:100vh;
position: relative;
overflow: hidden;
`

const Main =  styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 1.5rem;
  width: 65vw;
  height: 70vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6rem + 1vw);
  backdrop-filter: blur(4px);
  overflow-y: auto;
  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  font-family: 'Ubuntu Mono', monospace;
  font-style: italic;
  ::-webkit-scrollbar {
    width: 5px;
    background: rgba(1,1,1,0);
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`




const AboutPage = () => {
  const text = (
    <p style={{position: "absolute", top: "10px"}}>I am a passionate (read: opinionated) developer based in the UK, currently employed in the finance sector and working on a combination of commercial customer-facing applications (both SAAS and Mobile), as well as internal and infrastructural systems. <br/><br/>
    I have commercial experience in a number of languages and technologies, but specialise in full stack .NET development and the surrounding ecosystem. 
    As a mid-20-something having been in the industry since I was a teenager, I have amassed a good deal of experience working for larger, international companies, as well as smaller companies, both through regular employment and contracting work. <br/><br/>
    Computing also dominates a lot of my leisure time; I have a keen interest in reading current research papers in fields which interest me, Machine Learning & AI research being one of my favorites, 
    and I spend a lot of my spare time coding in order to gain experience with new technologies and to keep my skills sharp. To this end, I take on a lot of contracting work where the project piques my interest - If you think you have a project I'd be a good fit for, then please get in touch!</p>
  );
  return (
      <ThemeProvider theme={DarkTheme}>
        <Box>
          <SocialIcons theme='dark'/>
          <ParticleComponent theme='dark' />
          <BrowserView>
            <Main>{text}</Main>
          </BrowserView>
          <MobileView>
            <Main style={{left: "4rem", top: "7rem"}}>{text}</Main>
          </MobileView>
          <BigTitle text="ABOUT" top="0%" left="5%" />
        </Box>
      </ThemeProvider>
  )
}

export default AboutPage
