export const Skills = {
    Languages: [
        "C#",
        "TypeScript",
        "JavaScript",
        "GoLang",
        "SQL",
        "jQuery",
        "HTML/5",
        "CSS",
        "Python",
        "Powershell",
        "VB.NET",
    ],
    Frameworks: [
        ".NET Core",
        "Docker",
        "EF Core",
        "Node.js",
        "React.js",
        ".NET Framework",
        "MVC",
        "React Native",
        "Bootstrap",
        "AngularJS",
        "ASP.NET Core",
        "WPF",
        "ASP.NET MVC",
        "RESTful APIs",
        "ASP.NET WebAPI",
        "ELK",
        "Gulp",
        "Metro",
    ],
    Tools: [
        "Linux",
        "Bash",
        "Git",
        "VS Code",
        "Jenkins",
        "PuTTY",
        "Windows/Server",
        "Visual Studio",
        "SSMS",
        "MS SQL Server",
        "Microsoft Azure",
        "Nuget",
        "IIS",
        "OSX",
        "Xcode",
    ],
    Concepts: [
        "Azure DevOps",
        "Jira",
        "Agile Methodologies",
        "Kanban",
    ],
}