import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { DarkTheme } from '../components/Themes'
import { Skills } from '../data/SkillsData'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

const Box = styled(motion.div)`

position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
width: 65vw;
height:55vh;
display: flex;

background: linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) bottom,
    linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) top;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    border-left: 2px solid ${props => props.theme.body};
    border-right: 2px solid ${props => props.theme.text};
    z-index:1;

`
const SubBox = styled.div`
width: 50%;
position: relative;
display: flex;

.pic{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0%);
    width: 100%;
    height: auto;
}
`

const Text = styled.div`
font-size: calc(1em + 1.5vw);
color: ${props => props.color};
padding: 2rem;
display: flex;
flex-direction: column;
justify-content: space-evenly;

&>*:last-child{
    color: ${props => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.5rem + 1.5vw);
    font-weight:300;

}`

const props = Object.keys(Skills);

let i = 0;
let prop = 0;
let canTransition = true;

const Intro = () => {
    const [skill, updateSkill] = useState(Skills[props[prop]][i]);
    if (canTransition) {
        canTransition = false;
        setTimeout(() => {
            canTransition = true;
            if (i >= Skills[props[prop]].length - 1) {
                i = 0;
                prop = prop === Object.keys(Skills).length - 1 ? 0 : prop + 1;
            }
            else{
                i++;
            }
            updateSkill(Skills[props[prop]][i]);
        }, 2500);
    }

    return (
        <Box
            initial={{ height: 0 }}
            animate={{ height: '55vh' }}
            transition={{ type: 'spring', duration: 1, delay: 0.5 }}
            style={{ overflowY: "hidden", overflowX: "hidden" }}
        >
            <SubBox>
                <Text color={DarkTheme.text} style={{ paddingLeft: "1rem" }}>
                    <h1 style={{ fontSize: isMobile ? "1em" : "auto" }}>Louie Harrison</h1>
                    <h6>Experienced full stack web developer</h6>
                    <p>Idealistic programmer, bouldering enthusiast, and general geek</p>
                </Text>
            </SubBox>
            <SubBox>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1.5 }}
                >
                    <BrowserView>
                        <Text color={DarkTheme.body}>
                            <br />
                            <br />
                            <h5>Proficient in...</h5>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                key={skill}>
                                <h2>{skill}</h2>
                            </motion.div>
                            <h5>...And available to hire on a freelance basis</h5>
                            <div></div>
                        </Text>
                    </BrowserView>
                    <MobileView>
                        <br />
                        <br />
                        <Text color={DarkTheme.body} style={{ paddingLeft: "1rem" }}>
                            <h6 style={{ marginBottom:"10px" }}>Proficient in...</h6>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                key={skill}>
                                <h5 style={{ marginBottom:"10px" }}>{skill}</h5>
                            </motion.div>
                            <h6>...And available to hire on a freelance basis</h6>
                            <div></div>
                        </Text>
                    </MobileView>
                </motion.div>
            </SubBox>
        </Box>
    )
}

export default Intro
