import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Github, LinkedIn, Instagram } from '../components/AllSvgs'
import {DarkTheme} from '../components/Themes'
import { BrowserView, MobileView } from 'react-device-detect';


const Icons = styled.div`
display: flex;
flex-direction: column;
align-items: center;

position: fixed;
bottom: 0;
left: 2rem;

z-index:3;

&>*:not(:last-child){
    margin: 0.5rem 0;
}

`

const Line = styled(motion.span)`
width: 2px;
height: 8rem;
background-color: ${props => props.color === 'dark' ? DarkTheme.text : DarkTheme.body  };
`

const SocialIcons = (props) => {

    const motiondiv =  (<motion.div
    initial={{transform:"scale(0)"}}
    animate={{scale:[0,0.75,1.25,1]}}
    transition={{type:'spring', duration:1, delay:0.5}}
    >
        <NavLink style={{color:'inherit', marginBottom:"3px"}}  target="_blank" to={{pathname:"https://uk.linkedin.com/in/louie-harrison-31820822a"}}>
            <LinkedIn width={25} height={25} fill={props.theme === "light" ? "#0000000" : ""}/>
        </NavLink>
        <NavLink style={{color:'inherit', marginBottom:"3px"}}  target="_blank" to={{pathname:"https://github.com/Louie1505"}}>
            <Github width={25} height={25} fill={props.theme === "light" ? "#0000000" : ""}/>
        </NavLink>
        <NavLink style={{color:'inherit', marginBottom:"3px"}}  target="_blank" to={{pathname:"https://www.instagram.com/thelouieharrison/"}}>
            <Instagram width={25} height={25} fill={props.theme === "light" ? "#0000000" : ""}/>
        </NavLink>
    </motion.div>);

    const line = (<Line color={props.theme}
    initial={
        {
            height:0
        }
    }
    animate={{
        height: '8rem'
    }}
    transition={{
        type:'spring', duration:1, delay:0.5
    }}
    />);

    return (
        <div>
            <BrowserView>
                <Icons>
                    {motiondiv}
                    {line}
                </Icons>
            </BrowserView>
            <MobileView>
                <Icons style={{left: "1rem"}}>
                    {motiondiv}
                    {line}
                </Icons>
            </MobileView>
        </div>
    )
}

export default SocialIcons
