import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import SocialIcons from '../subComponents/SocialIcons'
import Intro from './Intro'



const MainContainer = styled.div`
background: ${props => props.theme.body};
width: 100vw;
height: 100vh;
overflow:hidden;

position: relative;

h2,h3,h4,h5,h6{
  font-family:'Karla', sans-serif ;
  font-weight:500;
}
`

const Container = styled.div`
padding: 2rem;
`

const Contact = styled(NavLink)`
color: ${props => props.theme.text};
position: absolute;
top: 2rem;
right: calc(1rem + 2vw);
text-decoration: underline;
z-index:1;
`

const NavLinkItem = styled(NavLink)`
color: ${props => props.theme.body};
text-decoration: underline;
display: flex;
margin-left: 5%;
margin-bottom: 10px;
z-index:1;
`

const DarkDiv = styled.div`
position: absolute;
top: 0;
background-color: #000;
bottom: 0;
right: 50%;
width: ${'50%'};
height: ${'100%'};
z-index:1;
transition: height 0.5s ease, width 1s ease 0.5s;
padding-top: 3%;
`



const Main = () => {

    const motionh2 = (text) => {
        return (<motion.h2
        initial={{
            y:-200,
            transition: { type:'spring', duration: 1, delay:0.5}
        }}
        animate={{
            y:0,
            transition: { type:'spring', duration: 1, delay:0.5}
        }}
        whileHover={{scale: 1.1}}
        whileTap={{scale: 0.9}}
        >
            {text}
        </motion.h2>);
    }

    return (
        <MainContainer>
         <DarkDiv>
            <NavLinkItem to="/about">
                {motionh2("About")}
            </NavLinkItem>
            <NavLinkItem to="/skills">
                {motionh2("Skills")}
            </NavLinkItem>
            {/* <NavLinkItem to="/work">
                {motionh2("Work")}
            </NavLinkItem> */}
         </DarkDiv>
            <Container>
            <SocialIcons theme={'dark'} />
            <Contact target="_blank" to={{pathname:"mailto:contact@louieharrison.co.uk"}}>
                {motionh2("Contact")}
            </Contact>
            </Container>
            <Intro/>
        </MainContainer>
    )
}

export default Main
