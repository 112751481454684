import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from './Themes';
import { Developer} from './AllSvgs';
import SocialIcons from '../subComponents/SocialIcons';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitle'
import { isMobile } from 'react-device-detect';
import { Skills } from '../data/SkillsData'

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:100vh;
position: relative;
display: flex;
justify-content: space-evenly;
align-items: center;
flex-flow: wrap;
padding-top: 5rem
`

const Card = styled.div`
border: 2px solid ${props => props.theme.text};
color: #000000;
background-color: ${props => props.theme.body};
padding: 2rem;
margin: 2rem 4rem 2rem 7rem;
height: ${isMobile ? "40vh" : "50vh"};
z-index:3;
line-height: 1.5;
${isMobile ? "" : "width: 30vw"};
font-family: 'Ubuntu Mono',monospace;
display: flex;
${isMobile ? "flex-basis:  65%;" +
"flex-grow: 0;"+
"flex-shrink: 0;": ""}
flex-direction: column;
justify-content: flex-start;`

const Title = styled.h2`
display: flex;
justify-content: center;
align-items: center;
font-size: calc(1em + 1vw);
margin-bottom: 20px;
&>*:first-child{
margin-right: 1rem;
}
`

const Description = styled.div`
color: #000000;
font-size: calc(0.6em + 1vw);
padding: 0.5rem 0;
margin-bottom: 1.5rem;
strong{
    margin-bottom: 1rem;
    text-transform: uppercase;
}
ul,p{
    margin-left: 2rem;
}
`

const TabLink = styled.strong`
flex:${props => props.flexBasis}% 0 0; 
cursor:pointer;
text-decoration:underline;
color: ${props => props.currentIndex === props.selectIndex ? "#000000" : "#999999"}
`

const MySkillsPage = () => {
    const [index, setIndex] = useState(0)
    return (
        <ThemeProvider theme={lightTheme}>
            <Box>
                <SocialIcons theme='light'/>
                <ParticleComponent theme='light' />
                <BigTitle text="SKILLS" top="0%" left="5%" />
                <Card>
                    <Title>
                        <Developer width={40} height={40} /> Full-Stack Software Developer
                    </Title>
                    <Description>
                        Skills in which I have strong, demonstrable commercial experience
                    </Description>
                    <Description>
                        <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap"}}>
                            <TabLink onClick={() => {setIndex(0)}} flexBasis={29} selectIndex={0} currentIndex={index}>Tech Stack</TabLink>
                            <TabLink onClick={() => {setIndex(1)}} flexBasis={29} selectIndex={1} currentIndex={index}>Frameworks</TabLink>
                            <TabLink onClick={() => {setIndex(2)}} flexBasis={17} selectIndex={2} currentIndex={index}>Tools</TabLink>
                            <TabLink onClick={() => {setIndex(3)}} flexBasis={20} selectIndex={3} currentIndex={index}>Concepts</TabLink>
                        </div>
                        <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap", fontSize: "0.8em"}}>
                            {Skills[Object.keys(Skills)[index]].map((s) => {
                                return (<p key={s} style={{flex:"33% 0 0", marginLeft: "0px"}}>•{s}</p>);
                            })}
                        </div>
                    </Description>
                </Card>
                <Card>
                    <Title>
                        <Developer width={40} height={40} /> Experience
                    </Title>
                    <Description>
                        Commercial experience <br/>(In Chronological Order)
                    </Description>
                    <Description>
                    <div style={{fontSize: "0.8em"}}>
                        <p>• Technical Support</p>
                        <p>• Database Administrator & Network Administrator</p>
                        <p>• Junior Full-Stack Software Developer</p>
                        <p>• Full-Stack Software Developer</p>
                    </div>
                    </Description>
                </Card>
            </Box>
        </ThemeProvider> 
    )
}

export default MySkillsPage
